.form-check-input:checked {
    background-color: #ffa459 !important;
}

.custom-calendar .rmdp-week-day{
    color: #ffa459 !important;
}

.rmdp-day:not(.rmdp-deactive) .highlight-red {
    color: #fff !important;
    background: red;
    padding:0;

}

.rmdp-day.rmdp-today span{
    background-color: #2ecc70b3 !important;
}

.rmdp-day.rmdp-selected span:not(.highlight){
    background-color: #ffa459 !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #ffa459 !important;
}

.rmdp-arrow {
    border: solid #ffa459 !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
 
    background: white !important;
    box-shadow: none !important;
}

.rmdp-day span {
    font-weight: 500 !important;
}

.rmdp-action-button{
    color:#ffa459 !important;
}

.price-mobile{
    font-size: 24px;
     color: #ffa459;

     @media screen and (max-width:768px){
        font-size: 18px;
     }
}



.timepickerContainer {
    width: 240px !important;
    padding: 20px 24px 0;
    color: #fff;
}

.timepickerSelects {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
}

 .timepickerSelects > .timer-section {
        width: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .timepickerSelects > .timer-section > div{
    font-size: 16px;
    }


   .timepickerSelects > button {
        padding: 10px 4px;
        background: transparent;
        font-size: 16px;
    }

    .timepickerSelects > button:disabled{
            opacity: .6;
            cursor: not-allowed;
        }

.timepickerBtns {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 40px;
    font-size: 13px;
}

.timepickerBtns > button {
    background: transparent;
    border: none;
    color:#fff;
    font-weight:600;
}


.rmdp-mobile.rmdp-wrapper {
    border: none !important;
    background: #ffa459 !important;
}


.timepickerSelects > div > select {
    width: 44px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%23FFFFFF" fill="%23FFFFFF" fill-rule="evenodd"/></svg>');
}

.timepickerSelects > div > button {
    padding: 10px 4px;
    background: transparent;
    border: none;
    color:#fff;
    font-weight: 900;

    &[disabled] {
        opacity: .6;
        cursor: not-allowed;
    }
}

.custom-calendar .rmdp-week-day {
    color: #fff !important;
}

.rmdp-day, .rmdp-week-day {
    color: #fff !important;
}


.rmdp-day-picker {
    width: 100%;

}


@media screen and (max-width: 768px) {
    .rmdp-day-picker >  div {
        width: 100%;
    }
   
}

.rmdp-day:not(.rmdp-deactive) .highlight-red {
    color: #fff !important;
    background: red;

}

.rmdp-mobile .rmdp-day-picker {
    margin-left: 0 !important;
}

.rmdp-calendar {
    width: 100% !important;
}

.rmdp-mobile.rmdp-wrapper {
    border: none !important;
    background: #ffa459 !important;
}

.rmdp-day.rmdp-disabled {
    color: #ff8d2f !important;
}

.rmdp-day.rmdp-today span {
    background-color: transparent !important;
    border-bottom: 3px solid #fff !important;
    border-radius: 0 !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #2ecc71 !important;
    border-radius: 8px !important;
    border: none !important;
}


.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background: #e4e7e7 !important;
    border: 1px double #e4e7e7;
    border-radius: 5px;
    color: #000 !important;
}

.rmdp-arrow {
    border: solid #fff !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
    background: transparent !important;
    box-shadow: none !important;
}

.time-picker .rmdp-arrow {
    border: solid var(--marketplaceColorDark) !important;
    border-width: 0 3px 3px 0 !important;
}

.time-picker .rmdp-time-picker input {
    width: 30px !important;
}

.time-picker .rmdp-time-picker .rmdp-am {
    width: 30px !important;
    display: flex !important;
    justify-content: center !important;
    padding: 5px 2px;
    margin: 0;
}

.rmdp-header-values {
    color: #fff !important;
}

.rmdp-day span {
    font-weight: 500 !important;
}

.rmdp-action-button {
    color: #fff !important;
    background: transparent !important;
}

.rmdp-calendar-container-mobile .rmdp-mobile.rmdp-wrapper {
    @media screen and (max-width: 768px) {
        width: 90vw;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.selected {
    position: relative;
    option:disabled {
        display: none;
    }
}


.manualForm {
    position: relative;
}

button.remove {
    position: absolute;
    right: 50px;
    top: 10px;
    padding: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    z-index: 1;
    background: transparent;
    border: none;

    img {
        width: 16px;
        height: 16px;
    }
    
}

.remove > i {
color:#ffa459;
font-size: 26px;
}

.title {
    color: #b2b2b2;
    font-size: 24px;
    line-height: 24px;
    font-weight: 900;
    margin-bottom: 10px;
    text-align: center;
}

.availabilityNote {
    font-size: 14px;
    line-height: 16px;
    color: #4a4a4a;
    margin-bottom: 8px;
    text-align: center;
}

.availability {
    margin-bottom: 30px;
    color: #2ecc71;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;

    @media screen and (max-width: 1023px) {
        margin: 8px 0 20px;
    }
}

.note {
    color: red;
    font-weight: 500;
    line-height: 12px;
    font-size: 13px;
    margin-bottom: 14px;
    text-align: center;
}

.chargeNote {
    font-size: 13px;
    line-height: 16px;
    color: #acacac;
    text-align: center;
    font-weight: 500;
    margin-bottom: 8px;
}

.label {
    font-weight: 600;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
    font-size: 14px;
}

.rmdp-day, .rmdp-week-day {
    width: 60px !important;
    height: 60px !important;
}

.rmdp-day.full {
        cursor: not-allowed;
        pointer-events: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

    }



.full >  span {
        opacity: .6;
        background-color: rgba(0, 0, 0, 0.15);
    }    

  .rmdp-day >  span {
        border-radius: 8px !important;
    }

  
.seats-available {
    position: absolute;
    bottom: 6px;
    right: 6px;
    font-size: 12px;
    line-height: 1;
    color: #35ff68;
}

.rmdp-day > .full {
    color: red;
}

.rmdp-selected  > .seats-available {
        color: #fff;
    }


.rmdp-action-buttons::before {
    content: 'Number in the bottom right corner displays the amount of available seats for the particular date';
    color: #fff;
    font-size: 11px;
    line-height: 1.3;
    width: 270px;
    text-align: left;
}

.pac-container {
    z-index: 1100 !important;
}

.apply {
    font-size: 14px;
    font-weight: bold;
    color: #ed2626;
    cursor: pointer;
    position: absolute;
    top:50%;
    right: 1%;
    transform: translate(-1%, -50%);
  }

  .listing-remove {
    padding: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    z-index: 1;
    background: transparent;
    border: none;
}

.listing-remove img {

        width: 16px;
        height: 16px;    
}


.listing-remove > i {
    color:#fff;
    font-size: 18px;
    }