.custom-calendar .rmdp-week-day{
    color: #fff !important;
}
.rmdp-day, .rmdp-week-day{
    color:#fff !important;
}


.rmdp-day-picker {
width: 100%;

@media screen and (max-width:768px) {
    div{
        width: 100%;
    }   
}
}

.rmdp-day:not(.rmdp-deactive) .highlight-red {
    color: #fff !important;
    background: red;

}

.rmdp-mobile .rmdp-day-picker{
    margin-left: 0 !important;
}

.rmdp-calendar{
    width: 100% !important;
}

.rmdp-mobile.rmdp-wrapper {
    border:none !important;
    background: #ffa459 !important;
}

.rmdp-day.rmdp-disabled {
    color: #ff8d2f !important;
}

.rmdp-day.rmdp-today span{
    background-color: transparent !important;
    border-bottom: 3px solid #fff !important;
    border-radius: 0 !important;
}

.rmdp-day.rmdp-selected span:not(.highlight){
    background-color: #2ecc71 !important;
    border-radius: 50% !important;
    border: none !important;
}



.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
     /* background-color: #ffa459 !important; */
    background: #e4e7e7 !important;
    border: 1px double #e4e7e7;
    border-radius: 5px;
    color: #000 !important;
}

.rmdp-arrow {
    border: solid #fff !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
    background: transparent !important;
    box-shadow: none !important;
}

.time-picker .rmdp-arrow {
    border: solid var(--marketplaceColorDark) !important;
    border-width: 0 3px 3px 0 !important;
}

.time-picker .rmdp-time-picker input {
    width: 30px !important;
}

.time-picker .rmdp-time-picker .rmdp-am {
    width: 30px !important;
    display: flex !important;
    justify-content: center !important;
    padding: 5px 2px;
    margin: 0;
}

.rmdp-header-values {
    color:#fff !important;
}

.rmdp-day span {
    font-weight: 500 !important;
}

.rmdp-year-picker, .rmdp-month-picker{
    padding: 5px;
    top:5px;
    height: max-content;
}
.rmdp-month-picker .rmdp-ym{
    height: auto;
}

.rmdp-year-picker, .rmdp-day{
    height: max-content !important;
}


.rmdp-ym .rmdp-day span {
    color: #fff;
    background: #ffa459
}

.rmdp-ym .rmdp-day.rmdp-today span {
    color: #fff;
    background: #ffa459 !important
}

.rmdp-ym .rmdp-day.rmdp-disabled span {
    color: #fff;
    background: #ed2626;
}


.rmdp-action-button{
    color:#fff !important;
    background: transparent !important;
}
.rmdp-calendar-container-mobile .rmdp-mobile.rmdp-wrapper{
    @media screen and (max-width:768px) {
        width: 90vw;
        top:70%;
        transform: translate(-50%, -70%);
        
    }
}

/* //.rmdp-mobile .rmdp-day, .rmdp-mobile .rmdp-week-day {
//    height: 40px;
//    width: 40px;
//}
//
//@media (max-width: 420px) {
//    .rmdp-mobile .rmdp-day, .rmdp-mobile .rmdp-week-day {
//        height: 40px;
//        width: 40px;
//    }
//} */
