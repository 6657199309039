.analytics {
    padding: 50px 2.25rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.analytics.last {
    padding-bottom: 52px;
}

.analytics-value {
    color: var(--bs-gray-800);
    font-size: 16px;
    font-weight: 500;
    margin: 2px 0;
}

.analytics-value span {
    color: rgb(255, 164, 41);
    font-weight: 800;
}
