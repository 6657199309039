.fc-daygrid-day-top {
    justify-content: center !important;
}

.fc-toolbar-chunk:first-child > div {
    display: flex;
    align-items: center;
}

#fc-dom-2 {
    font-size: 13px;
    font-weight: 500;
    margin-left: 1rem;
}

.fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0.5rem 1rem;
}

.fc-button {
    background: none !important;
    border: none !important;
    outline: none !important;
    color: #000 !important;
}

.fc-button:focus {
    box-shadow: none !important;
}

.fc-today-button {
    text-transform: uppercase !important;
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}

.fc-col-header-cell-cushion {
    font-weight: 300;
}

.fc-myCustomButton-button {
    margin-left: 2rem !important;
    font-weight: 600 !important;
}

.fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end), .fc-direction-rtl .fc-daygrid-block-event:not(.fc-event-start) {
    border-color: transparent !important;
}

a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-past {
    z-index: 0 !important;
}

a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-today {
    z-index: 0 !important;
}

.fc .fc-toolbar-title {
    font-size: 13.5px !important;
    font-weight: 600 !important;
    margin: 0;
}

.fc-daygrid-day-top {
    height: 100%;
    width: 100%;
}

.fc .fc-daygrid-day-number {
    width: 100%;
}

.fc-daygrid-day-frame {
    height: 100px;
}

.fc .fc-highlight {
    background-color: inherit;
}

.fc .fc-day-disabled {
    background-color: #ebebeb !important;
    opacity: 0.4 !important;
}

.fc .closed-day{
    background-color: #ed2626 !important;
    opacity: 1;
    /* cursor: not-allowed */
}


.fc .fc-daygrid-more-link {
    display: none;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: inherit;
}

.fc .day-cell-content-date {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.fc-button {
    width: max-content !important;
}

.fc .day-cell-content-title {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 0;
}

.fc .fc-bg-event {
    background-color: #e6e6e6;
    opacity: 1;
}

.fc .fc-bg-event .fc-event-title {
    visibility: hidden;
}

.calendar-legend{
    display: flex;
    gap:30px;
    margin-top: 25px;
}


.calendar-legend-item{
    display: flex;
    align-items: center;
    gap: 10px;
}

 .status-box{
    width: 20px !important;
    height: 20px !important;
    border-radius: 2px;
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
}

.calendar-legend-item > h6 {
    margin-bottom: 0;
}

.available{
    background-color: #fff;
}

.booked{
    background-color: #e6fff0;
}

.closed{
    background-color: #ed2626;
}


.watermark {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    font-size: 100px;
    font-weight: 700;
    background-color: rgba(255,255,255,0.4);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffa459;
    border-radius: 10px;
}

.watermark > h1 {
    font-size: 46px;
    font-weight: 900;
    /* transform: rotate(-50deg); */
    letter-spacing: 5px;
}

.fc .fc-daygrid-body{
    z-index: 0;
}