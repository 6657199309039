.rest-stop-container{
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(4, 1fr);
}



.setting-btn{
    width: 25%;
}


@media screen and (max-width:768px) {
    .rest-stop-container, .listings-container{
        grid-template-columns: 1fr 1fr;
    }
    .setting-btn{
        width: 100%;
    }
}


.listings-container{
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);
}

.listing-remove {
    padding: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    z-index: 1;
    background: transparent;
    border: none;
}

.listing-remove img {

        width: 16px;
        height: 16px;    
}


.listing-remove > i {
    color:#ed2626;
    font-size: 18px;
    }