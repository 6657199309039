button {
    color: #fff;
    background: #000;
    border: 0;
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    font-weight: bold;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.container h1,
.container h2,
.container h3 {
    color: rgb(255, 255, 107);
}

.left {
    display: flex;
    align-items: flex-start;
}

.left>div:not(.info) {
    padding-left: 25px;
}

.right a {
    display: block;
    padding: 10px;
    background-color: #000;
}

.marker {
    transform: scale(0);
    animation: 400ms reveal forwards;
}

.mapContainer {
    position: relative;
    width: 100%;
    height: 410px;
}

@keyframes reveal {
    to {
        transform: scale(1);
    }
}

@media screen and (max-width: 767px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
    }

    .left {
        flex-direction: column;
    }

    .left>div:not(.info) {
        padding-left: 0;
    }
}

.priceMarker {
    display: flex;
    background-color: #ffffff;
    border-radius: 2px;
    padding: 4px 6px;
    font-size: 14px;
    font-weight: var(--fontWeightMedium);
    position: relative;
    box-shadow: var(--boxShadowBreakdown);
    transition-duration: .2s;
    transform: translate(-50%, calc(-100% - 4px));
    z-index: 110;

    &.highlighted {
        background-color: var(--marketplaceColor);
        color: #fff;
        transform: translate(-50%, calc(-100% - 4px)) scale(1.15);
        z-index: 111;

        &::after {
            border-top-color: var(--marketplaceColor);
        }
    }

    &:hover
    {
    background-color: var(--marketplaceColor);
    color: #fff;
    transform: translate(-50%, calc(-100% - 4px)) scale(1.03);
    z-index: 111;

    &::after {
        border-top-color: var(--marketplaceColor);
    }
}

&::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-top: 4px solid #ffffff;
}
}