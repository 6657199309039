  .characters {
    height: 50px;
  }
  
  .character {
    position: relative;
    width: 50px;
    line-height: 50px;
    font-size: 28px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    margin-left: 16px;
    color: #272729;
    text-align: center;
    border: none;
    box-shadow: 0px 0px 20px 0px #2727291a;
  }
  
  .character--inactive {
    background-color: transparent;
    color:#91AFD7;
  }
  
  
  .character--selected {
    border: 1px solid #C8DCFA;
  }

  .character--filled{
    border: 1px solid #34CD77;
  }
  
  .character--error:not(.character--selected){
    border: 1px solid #FF2C2C;
  }


   
  .character--selected:not(.character--filled)::after {
    content: "";
    width: 1px;
    height: 25px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }
   
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }