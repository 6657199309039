.table-responsive{
height: 75vh;
overflow-y: auto;

.table{
border-collapse: collapse;
border-spacing: 0px !important;
}
th {
    padding: 0.25rem;
  }

th:first-child, td:first-child{
    padding-left: 6px !important;
}

th:last-child{
padding-right: 6px !important;
}


.table > thead > tr > th {
background: #FFA459;
color: #FFF;
border:none !important;
}

.table > tbody > tr > td{
border:none !important;
box-shadow: none;
background: transparent;
}


.table > tbody > tr:nth-child(even){
  border:none !important;
  box-shadow: none;
  background: #f4f6fa;
  }

.table > thead > tr{
  background: #FFA459;
  }

.table > thead > tr > th{
position: sticky;
top: 0;
z-index: 2;
}

.table > thead > tr > .p-60{
padding-left: 70px !important;
}

.table > :not(caption) > * > * {
border-bottom-width: 0px;
}

.table.gy-5 td{
  padding-left: 0.25rem;
}


}


p * u{
  text-decoration-color: red !important;
  }

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: #e5eaee;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 3px solid #ffa459;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: -12px;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 3px solid #ffa459;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -12px;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 3px solid #ffa459;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -12px;
}

input[type="range"]:hover::-webkit-slider-thumb,
input[type="range"]:focus::-webkit-slider-thumb {
  transform: scale(1.1);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:focus::-moz-range-thumb {
  transform: scale(1.1);
}

input[type="range"]:hover::-ms-thumb,
input[type="range"]:focus::-ms-thumb {
  transform: scale(1.1);
}

input[type="range"]:focus {
  outline: none;
  background: #e5eaee;
}

.listing_title {
word-break: break-all;
}