.breakdown {
    font-size: 14px;
}

.dates {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
        text-align: right;
    }
}

.label {
    font-size: 15px;
    line-height: 24px;
    color: #b2b2b2;
    margin-bottom: 6px;
    font-weight: 600;
}

.title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    color: #4a4a4a;

}

.calculation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    margin: 8px 0;
    padding: 8px 0;
}

.promo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    margin: 4px 0;
    padding: 4px 0 8px 0;
}

.total {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #4a4a4a;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.note {
    font-size: 13px;
    line-height: 16px;
    color: #4a4a4a;
    font-weight: 500;
    margin-top: 12px;
}
