.remove {
    position: absolute;
    right: 50px;
    top: 10px;
    padding: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    z-index: 1;
    background: transparent;
    border: none;
}

.remove img {

        width: 16px;
        height: 16px;    
}


.remove > i {
    color:#ffa459;
    font-size: 26px;
    }