.w-135{
    min-width: 135px;
}

.w-240{
    min-width: 240px;
}

.w-220{
    width: 220px;
}

.w-170{
    min-width: 170px;
}