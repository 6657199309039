.form-check-input:checked {
    background-color: #ffa459 !important;
}

.form-check-input:disabled {
    opacity: 1;
}

.vehicle-control {
    background-color: transparent !important;
    border: transparent !important;
    border-bottom: 2px solid #ffa459 !important;
    border-radius: 0 !important;
    outline: transparent !important;
    padding-left: 0 !important;
    font-weight: 700 !important;
}

.heading-title {
    font-size: 35px;
    color: #4a4a4a;
}

@media screen and (max-width: 768px) {
    .heading-title {
        font-size: 18px;
    }
}

.mobile-hide {
    display: block;
}

@media screen and (max-width: 768px) {
    .mobile-hide {
        display: block;
    }
}

.desktop-hide {
    display: block;
}

@media screen and (max-width: 768px) {
    .desktop-hide {
        display: none;
    }
}

.booking-wrapper {
    height: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.listing-wrapper {
    height: 420px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.vehicle-wrapper {
    height: 330px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.booking-type-header {
    gap: 30px;
}

.booking-type-header button {
    font-size: 16px
}

@media screen and (max-width: 768px) {
    .booking-type-header {
        gap: 10px;
        flex-wrap: wrap
    }

    .booking-type-header > button {
        font-size: 14px
    }
}

.pac-container {
    display: block;
    z-index: 99999 !important;
}

.modal_wrapper {
    position: fixed;
    background: rgba(0, 0, 0, .6);
    width: 100vw;
    height: 100vh;
    z-index: 99;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center
}

@media screen and (max-width: 768px) {
    .modal_wrapper {
        left: 0;
    }
}

.modal_container {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    width: 380px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5)
}

.modal_btn_container {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.modal_btn_container > button {
    background: #ffa459;
    height: auto;
    width: 50%;
    color: #fff;
}


.booking_title{
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.disable_api_btn:disabled{
    opacity: 1 !important;
}

.hover-text{
    transition: all 0.3s ease;
}

.hover-text:hover{
    color: #2ecc71!important;
}

.topup-container > .topup{
    border-radius: 5px;
    border: 2px solid #2ecc71 !important;
    color: #000 !important;
}

.topup-container > .remove-cash{
    border-radius: 5px;
    border: 2px solid #ed2626 !important;
    color: #000 !important;
}