.container {
    width: 1100px;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1023px) {
        width: 100vw;
        flex-direction: column-reverse;
        padding: 40px 16px;
    }

    h1 {
        margin: 0;
        font-weight: 600;
    }
}

.main {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    row-gap: 46px;
    width: 520px;

    @media (max-width: 1023px) {
        width: 100%;
    }
}

.breakdown {
    width: 420px;
    min-width: 400px;
    border-radius: 2px;
    border: 1px solid #e7e7e7;

    @media (max-width: 1023px) {
        width: 100%;
        min-width: unset;
        margin-bottom: 32px;
    }
}

.image {
    height: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content {
    border-top: 1px solid #e7e7e7;
    padding: 0 48px 55px;
    background-color: #ffffff;

    @media (max-width: 1023px) {
        padding: 0 16px 30px;
    }
}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffb77c;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
}

.listing {
    margin: 18px 0 10px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
}

.title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #b2b2b2;
    margin-bottom: 16px;
}

.disclaimer {
    margin-top: 32px;
    color: #ff0000;
    text-align: center;

    a {
        color: #ff0000;
        text-decoration: underline;
    }
}

.list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    // margin-bottom: 44px;
}

.cardItem {
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 10px;

    &.active {
        border: 1.5px solid  #ffa459;

    }
}

.icon {
    img {
        width: 35px;
    }
}

.number {
    margin-left: 12px;
    margin-right: 24px;
}

.form {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.card {
    border-bottom: 3px solid #ffa459;
    padding-bottom: 6px;
}

.billing {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    button {
        margin-top: 52px;
    }
}
.inputs {
    display: block;
    flex-wrap: wrap;
    gap: 40px 30px;
    flex-direction: column;

    > div {
        flex-basis: 40%;
        flex-grow: 1;
        flex-shrink: 1;
        margin-bottom: 1em;
    }
}

.addCard {
    margin-bottom: 42px !important;
}
