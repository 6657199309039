.universal-day-wrapper{
padding-bottom: 1.25rem;
border-bottom: 1px dotted #b2b2b2;
}

.day-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dotted #b2b2b2;
    padding-bottom: 1.25rem;
    gap: 10px;


    .day {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 18%;
    }

    .time-block-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 60%;
        .time-block {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
        }
    }

}