.wrapper {
    display: flex;
    font-size: 14px;
    line-height: 26px;
    color: #2ecc71;
    font-weight: 700;
    margin-bottom: 5px;
}

.time {
    font-weight: 900;
}
