.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.canvas{
    width: auto;
    border: 2px solid #000;
    border-radius: 5px;
    }

.hide-canvas {
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    visibility: hidden;
}

.reject-reason {
position: absolute;
bottom:0;
left:0;
width: 100%;
}