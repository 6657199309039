.booking-edit-status-wrapper{
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .booking-edit-status-wrapper{
        flex-direction: column;
    }
    
}


.booking-edit-btn-wrapper{

        gap: 12px;
        display: flex;
        justify-content: end;
        align-items: center;
        flex-wrap: wrap
    
}


@media screen and (max-width:768px) {
    .share-wrapper{
        flex-direction: column;
        width: 100% !important;
        gap:5px !important
    }

   .share-wrapper > div{
        width: 100%;
    }
    .share-email{
        width: 100% !important;
    }
}

.remove-share{
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0px, -50%);

    @media screen and (max-width: 768px) {
        width: 40px;
    }
}

.remove-share > i {
    color:#ffa459;
    font-size: 26px;
    }
    

@media screen and (max-width: 768px) {
    .booking-edit-btn-wrapper{
        justify-content: space-between;
        gap:5px;
    }
    button{
        width: 100%;
        
    }
    
}

.share-btn > i {
    color:#fff;
    font-size: 26px;
}

.vehicle-label{
    width: 30%;
}
    
 @media screen and (max-width: 768px) {
       .vehicle-card{
           flex-direction: column; 
       } 

       .vehicle-label{
        width: 100%;
       }
    }


    .modal_wrapper_booking {
        position: fixed;
        background: rgba(0, 0, 0, .6);
        width: 100vw;
        height: 100vh;
        z-index: 999;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center
    }
    
    @media screen and (max-width: 768px) {
        .modal_wrapper {
            left: 0;
        }
    }
    
    .modal_container {
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        width: 535px;
        margin: 0 auto;
        padding: 20px;
        background: #fff;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5)
    }

    @media screen and (max-width:768px) {
        .modal_container{
            width: 95vw !important;
        }
    }
    

    .modalListingName{
        font-size: 18px;
        line-height: 24px;
        color: var(--matterColor);
        margin: 6px 0;
    
     
    }
    

    .modalListingName > span {
        color: #ed2626;
    }


    .modal_btn_container {
        display: flex;
        gap: 15px;
        margin-top: 15px;
    
       
    }
    
    .modal_btn_container >  button {
        background: #ffa459;
        height: auto;
        width: 50%;
        color: #fff;
    }



    .apply {
        font-size: 14px;
        font-weight: bold;
        color: #ed2626;
        cursor: pointer;
        position: absolute;
        top:50%;
        right: 1%;
        transform: translate(-1%, -50%);
      }
    
      .listing-remove {
        padding: 0;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        z-index: 1;
        background: transparent;
        border: none;
    }
    
    .listing-remove img {
    
            width: 16px;
            height: 16px;    
    }
    
    
    .listing-remove > i {
        color:#fff;
        font-size: 18px;
        }  


        .chargeNote {
            font-size: 13px;
            line-height: 16px;
            color: #acacac;
            text-align: center;
            font-weight: 500;
            margin-bottom: 8px;
        }      

        .pac-container {
            z-index: 1100 !important;
        }
              

        .vehicle-price{
        position: absolute;
        right: -5px;
        top: -16px;
        border-radius: 5px;
        background-color: #ffa459;
        color: #fff;
        padding: 5px 10px;
        }