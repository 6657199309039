.imageViewer > .container > .imagesWrapper{
    width: 100vw !important;
}

.imageViewer > .container {
    position: static !important;
}

.checked{
    color:#e7e7e7;
    text-decoration: line-through;
}

.form-check-input:checked {
    background-color: #ffa459 !important;
}

.marker{
    height: 57px;
    margin-top: -57px;
    margin-left: -20px;
}

.pac-container{
    z-index: 99999 !important;
    /* display: block !important; */
}

.map{
    width: 85%;

    @media screen and (max-width:768px) {
        width: 100%;
    }
}

.mobile-price{
    font-size: 22px;
    @media screen and (max-width:768px) {
        font-size: 1.4rem;
    }
}

.mobile-price-sub{
    font-size: 14px;
    @media screen and (max-width:768px) {
        font-size: 12px;
    }
}

.modal_wrapper {
    position: fixed;
    background: rgba(0, 0, 0, .6);
    width: 100vw;
    height: 100vh;
    z-index: 99;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center
}

@media screen and (max-width: 768px) {
    .modal_wrapper {
        left: 0;
    }
}

.modal_container {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    width: 380px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5)
}

.modal_btn_container {
    display: flex;
    gap: 15px;
    margin-top: 15px;

   
}

.modal_btn_container >  button {
    background: #ffa459;
    height: auto;
    width: 50%;
    color: #fff;
}

.overlay{
    position:absolute;
    right: 2%;
    bottom: 5%;
    color: #000;
    padding:5px 20px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
}
.overlay:hover{
    background-color: #ffa459;
    color:#fff
}

.cover-img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    object-fit: cover;
}

.cover-img:hover{
        transform: scale(1.005);
}

.booking-wrapper{
    height: 310px;
    overflow-y: scroll;
    overflow-x: hidden;   
    @media screen and (max-width:768px){
        height: 500px;
    }
}

.booking-type-header{
    gap:30px;

}

.booking-type-header button {
    font-size: 16px
}

@media screen and (max-width:768px) {
   .booking-type-header{
       gap:10px;
       flex-wrap:wrap
   }
   .booking-type-header > button {
    font-size: 14px
}
   
}

.ql-editor * u{
text-decoration-color: red !important;
}

.vehicle-active{
background-color: #ffa459 !important;
color: #fff !important;
border-radius: 5px !important;
}

.vehicle-active:hover{
    background-color: #ffa459 !important;
    color: #fff !important;
}

.disable-btn:disabled{
    opacity: 1 !important;
}