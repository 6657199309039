.w-135{
    width: 135px;
}

.w-150{
    width: 150px;
}

.w-180{
    width: 180px;
}

.w-60{
    width: 60px;
}

.w-100{
    width: 80px;
}

.w-110{
    width: 110px;
}

.w-115{
    width: 115px;
}

.w-220{
    width: 220px;
}

.w-240{
    min-width: 240px;
}

.w-250{
    width: 250px;
}

.w-280{
    min-width: 280px;
}


.w-200{
    width: 200px;
}

.active-rating{
    color: #ffa459;
    font-size: 18px;
}

.inactive-rating{
    color: #b2b2b2;
    font-size: 18px;
}

.pointer{
    cursor: pointer;
}