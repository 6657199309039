@media screen and (max-width:768px) {
    .card-title{
        flex-direction: column-reverse;
        width: 100%;
    }

    .dropdown-search{
        width: 100%;
    }

    .search-container{
        width: 100%;
    }

    .user-search-input{
        width: 100% !important;
    }
    
}